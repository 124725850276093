import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Container, Columns, Column, Content, Title, Section } from 'bloomer'

export default () => (
  <Layout>
    <SEO title="Boarding Kennel Agreement" alias="boarding-kennel-agreement" />
    <Section>
      <Container className="container--narrow">
        <Columns>
          <Column>
            <Title tag="h1" hasTextAlign="centered">
              Boarding Kennel Agreement
            </Title>
            <Content>
              <p style={{ 'text-align': 'justify' }}>
                The following is a copy of the standard boarding kennel
                agreement we require all customers boarding dogs or cats with us
                to sign. Blank fields have been replaced with [ bracketed ]
                place-holders.
              </p>
              <p style={{ 'text-align': 'center' }}>
                <strong>BETWEEN: THIS AGREEMENT</strong> is made this [ day ]
                day of [ month ], [ year ]
              </p>
              <p style={{ 'text-align': 'center' }}>
                <strong>SAMUEL HEWITT and JUDY HEWITT, Jointly</strong> – of the
                Township of Huron-Kinloss, in the County of Bruce
                <br />
                Hereinafter called “Hewitts”, OF THE FIRST PART
              </p>
              <p style={{ 'text-align': 'center' }}>-and-</p>
              <p style={{ 'text-align': 'center' }}>[ pet owner ]</p>
              <p style={{ 'text-align': 'center' }}>
                of the Township of [ township ], in the County of [ county ],
                <br />
                Hereinafter called “the Pet Owner” OF THE SECOND PART
                <br />
                <strong>WHEREAS</strong> THE Hewitts operate a pet boarding
                kennel at part of lots 51 and 52, Concession 1<br />
                in the Township of Huron-Kinloss, in the County of Bruce shown
                as Part 1 on Plan 3R-7090
                <br />
                <strong>AND WHEREAS</strong> the Pet Owner wishes to board [
                number of pets ] dog(s) [ number of pets ] cat(s)
              </p>
              <p style={{ 'text-align': 'center' }}>
                with the Hewitts for [ number ] days from [ arrival date ] to [
                pickup date ]
              </p>
              <p>
                <strong>AND WHEREAS</strong> the Hewitts wish to clearly limit
                the extend of their liability as set out in this agreement.
                <br />
                <strong>NOW THEREFORE IN CONSIDERATION OF</strong> the terms and
                conditions contained herein and other good and valuable
                consideration the parties hereto agree as follows:
              </p>
              <ol>
                <li>
                  The Hewitts shall care for the Pet Owner’s pet(s) for the said
                  period which includes feeding and housing and reasonable
                  supervision and containment.
                </li>
                <li>
                  It is expressly acknowledged and agreed that the Hewitts shall
                  not be held liable for any claim or damages by the Pet Owner
                  in the event of the death of the pet(s) or in the event that
                  the pet(s) become ill or incapacitated in an way either during
                  their stay at the Hewitts or after their departure. It is the
                  intent that the pet(s) is left with the Hewitts at the sole
                  risk of the Pet Owner.
                </li>
                <li>
                  In the event that the pet(s) requires veterinary examination
                  and/or treatment in the opinion of the Hewitts during the
                  boarding period at the Hewitts, the Hewitts shall have the
                  right to engage the services of a veterinarian as they deem
                  reasonable for the benefit of the per(s), and the Pet Owner
                  shall be responsible for and does hereby agree to pay for the
                  veterinarian’s fees and charges for medication or related
                  materials.
                </li>
                <li>
                  In the event that the pet(s) is/are unclaimed after the
                  agreed, above, board ending date, the Hewitts reserve the
                  right to give over the cat(s) and/or dog(s) to the person or
                  persons of their choice, due to the failure of the pet(s)
                  owner to fully comply with the above agreement, and the
                  Hewitts will not then be held liable, at any future date.
                </li>
              </ol>
            </Content>
          </Column>
        </Columns>
      </Container>
    </Section>
  </Layout>
)
